import { Link } from "react-router-dom";

const Categories = () => {
    return ( 
        <div className="categories">
            <div className="ag-format-container">
                <div className="ag-courses_box">

                    <div className="ag-courses_item">

                    <Link to="/personal" className="ag-courses-item_link">
                        <div className="ag-courses-item_bg"></div>

                        <div className="ag-courses-item_title">
                        Persönliche Themen
                        </div>
                    </Link>
                    </div>

                    

                    <div className="ag-courses_item">
                    <a href="#" className="ag-courses-item_link">
                        <div className="ag-courses-item_bg"></div>

                        <div className="ag-courses-item_title">
                        Soziale Themen
                        </div>                    </a>
                    </div>


                   

                </div>
                </div>
        </div>
     );
}
 
export default Categories;