
import { useState } from "react";

const QuestionCard = ({number, data}) => {

    const get_depth = () => {
        let depth;
    switch(data[number][2]) {
        case 1:
            depth = "Einfach";
            break;
        case 2:
            depth = "Mittel";
            break;
        case 3:
            depth = "Tiefgründig";
            break;
        default:
            depth = "";
    }
    return depth;
    }
    

    return (
      <>
      <div className="gradient-cards">
          <div className="card">
          <div className="container-card bg-blue-box">
              <p className="card-title">{data.length-1 >= number && "Frage " + (number+1)}{number >= data.length && <>Keine Fragen mehr!<br />Ich hoffe, dass ihr dabei Spaß hattet! 🙂</>}</p>
              <p className="card-description">{data.length-1 >= number && data[number][0]}</p>
              <p className="card-description type">{data.length-1 >= number && data[number][1]}</p>
              <p className="card-description deepness">{data.length-1 >= number && "Deepness: " + get_depth(data[number][2])}</p>
          </div>
          </div>
      </div>    
      </>
    );
}

export default QuestionCard;