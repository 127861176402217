import useFetchExcel from "../scripts/useFetchExcel";
import QuestionCard from "../components/QuestionCard";
import { useState} from "react";
import { signal } from "@preact/signals-react";
import ArrowLeft from "../assets/svg/arrow-left-solid.svg"
import { useNavigate } from "react-router-dom";

const PersonalTopics = () => {

    const navigate = useNavigate();
    const handleBack = () => {
        console.log('back')
        navigate('/')
    }

    const { data, loading, error } = useFetchExcel("./data/Fragen.xlsx");
    const [number, setNumber] = useState(0);
    const [maxquestion, setMaxQuestion] = useState(true);
    
    
    // if(number === data.length-1){
    //     console.log("Counting")
    //     setMaxQuestion(true);
    // }


    return ( 
        <>
        <div className='heading'>
            <img src={ArrowLeft} className='arrow-left' onClick={handleBack}></img>
            <h2>Persönliche Fragen</h2>
          </div>
          <div className='horizontal-line'></div>
          <div className="personal-topics">
            {error && <div className="error">{"Fehler beim abrufen der Daten"}</div>}
            {data && <QuestionCard number={number} data={data}/>}
            <button className="btn next-q" onClick={() => setNumber(prevNumberr => prevNumberr + 1)}>Nächste Frage</button>
        </div>
        </>


        
     );
}
 
export default PersonalTopics;

