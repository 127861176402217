// import ArrowLeft from "./assets/svg/arrow-left-solid.svg"
import ArrowLeft from "../assets/svg/arrow-left-solid.svg"
import Categories from "../components/Categories";
import { Link, useNavigate } from "react-router-dom";
const Home = () => {
    return (
        <>
            <div className='heading'>
                {/* <img src={ArrowLeft} className='arrow-left' onClick={handleBack}></img> */}
                <h2>Christian Deep Talk</h2>
            </div>
            <div className='horizontal-line'></div>
            <Categories />
            
            
            

        </>
    );
}

export default Home;