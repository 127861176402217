import Categories from './components/Categories';
import './App.css';
import { BrowserRouter, Routes, Route, Link, useNavigate} from "react-router-dom"
import PersonalTopics from './pages/PersonalTopics';

import Home from './pages/Home';

function App() {

  const navigate = useNavigate();

  const handleBack = () => {
    console.log('back')
    navigate('/')

  }

  return (
      <div className="App">
        <div className='app-container'>

        <div className="snow"></div>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/personal" element={<PersonalTopics/>}/>
          </Routes>

        </div>
      </div>
  );
}

export default App;
