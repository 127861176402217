import { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';



const useFetchExcel = (filePath) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fragen, setFragen] = useState(null);

  useEffect(() => {
    const readExcel = async () => {
      try {
        const response = await fetch(filePath);
        const arrayBuffer = await response.arrayBuffer();
        const data = new Uint8Array(arrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const columns = {};
        const rows = [];
        const columnNames = jsonData[0];
        for (let i = 0; i < columnNames.length; i++) {
          columns[columnNames[i]] = [];}
        // Extract rows
        
        //remove first row and then Randomaize jsonData
        jsonData.shift();
        jsonData.sort(() => Math.random() - 0.5); 

        

        setData(jsonData);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }

      



    };
    readExcel();
  }, [filePath]);

  return { data, loading, error };
};

export default useFetchExcel;